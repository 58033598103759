import React from "react";
import styled from "styled-components";
import { Box, Flex, Heading2, Heading4, Text, Tooltip, theme } from "@nulogy/components";
import Checkbox from "components/controls/Checkbox";
import WorkBlockPreview from "components/common/Scheduler/SchedulerRenderers/components/blocks/WorkBlockPreview";
import LabelWithTooltip from "components/common/LabelWithTooltip";
import Radio from "components/controls/Radio";
import WorkBlock from "domain/WorkBlock";

const SectionHeader = styled(Heading2)`
  font-weight: ${theme.fontWeights.medium};
`;

const SubHeader = styled(Text)`
  margin-top: ${theme.space.x3};
  margin-bottom: ${theme.space.half};
`;

const Columns = styled(Box)`
  label {
    padding: ${theme.space.half} 0;
  }
`;

const CustomRadio = styled(Radio)`
  label {
    padding: ${theme.space.half} 0;
  }
`;

const LayoutHeading = styled(Heading4)`
  margin-bottom: ${theme.space.half};
  font-weight: ${theme.fontWeights.medium};
`;

function KeepTooltip() {
  return (
    <Tooltip
      tooltip="If selected, this field will appear on the work order card when expanded or collapsed."
      placement="right"
      maxWidth="220px"
    >
      <img
        width={theme.sizes.x2}
        height={theme.sizes.x2}
        src="./svg/components/pages/settings/keep.svg"
        alt="If selected, this field will appear on the work order card when expanded or collapsed."
      />
    </Tooltip>
  );
}

export default function LayoutSetting() {
  const WorkBlockLayout = () =>
    WorkBlock.LAYOUTS.map((layout) => {
      const blockPreview = <WorkBlockPreview heading={layout.label} />;
      const labelComponent = <LabelWithTooltip label={layout.label} tooltip={blockPreview} tooltipPlacement="right" />;

      return (
        <CustomRadio
          key={`work-block-layout-${layout.value}`}
          labelText={labelComponent}
          field="workBlockLayout"
          value={layout.value}
          tooltip={blockPreview}
          tooltipPlacement="right"
          className={`spec-work-block-layout-${layout.value}`}
        />
      );
    });

  return (
    <Flex flexDirection="column" gap="x4" mb="x2">
      <Box>Customize the layout of work blocks and work order cards on your production schedule.</Box>
      <Box data-testid="work-block-layout-settings">
        <SectionHeader>Work blocks</SectionHeader>
        <SubHeader>Select what should be displayed on the first line of a work block.</SubHeader>
        <WorkBlockLayout />
      </Box>
      <Box>
        <SectionHeader>Work order cards</SectionHeader>
        <SubHeader>Select the fields that will appear on a work order card.</SubHeader>
        <Flex gap="x8" mt="x2" data-testid="work-order-card-layout-setting">
          <Box>
            <Columns>
              <LayoutHeading>Work order fields</LayoutHeading>
              <Checkbox
                field="workOrderCardLayout[workOrderId]"
                labelText="Work order ID"
                className="spec-work-order-layout-field-work-order-id"
              />
              <Checkbox
                field="workOrderCardLayout[workOrderDescription]"
                labelText="Work order description"
                className="spec-work-order-layout-field-work-order-description"
              />
              <Checkbox
                field="workOrderCardLayout[materialAvailabilityDate]"
                labelText="Material availability date"
                className="spec-work-order-layout-field-material-availability-date"
              />
              <Checkbox
                field="workOrderCardLayout[plannedStartDate]"
                labelText="Planned start (from Shop Floor)"
                className="spec-work-order-layout-field-planned-start-date"
              />
              <Checkbox
                field="workOrderCardLayout[plannedEndDate]"
                labelText="Planned end (from Shop Floor)"
                className="spec-work-order-layout-field-planned-end-date"
              />
              <Checkbox
                field="workOrderCardLayout[customWorkOrderField]"
                labelText="Custom work order field"
                className="spec-work-order-layout-field-custom-work-order-field"
              />
              <Checkbox
                field="workOrderCardLayout[reference1]"
                labelText="Reference 1"
                className="spec-work-order-layout-field-reference-1"
              />
              <Checkbox
                field="workOrderCardLayout[reference2]"
                labelText="Reference 2"
                className="spec-work-order-layout-field-reference-2"
              />
              <Checkbox
                field="workOrderCardLayout[reference3]"
                labelText="Reference 3"
                className="spec-work-order-layout-field-reference-3"
              />
            </Columns>
          </Box>
          <Box>
            <Columns>
              <LayoutHeading>Item fields</LayoutHeading>
              <Flex gap="half">
                <Checkbox
                  field="workOrderCardLayout[itemCode]"
                  labelText="Item code"
                  className="spec-work-order-layout-field-item-code"
                />
                <KeepTooltip />
              </Flex>
              <Flex gap="half">
                <Checkbox
                  field="workOrderCardLayout[itemDescription]"
                  labelText="Item description"
                  className="spec-work-order-layout-field-item-description"
                />
                <KeepTooltip />
              </Flex>
              <Checkbox
                field="workOrderCardLayout[standardProductionRate]"
                labelText="Standard production rate"
                className="spec-work-order-layout-field-standard-production-rate"
              />
              <Checkbox
                field="workOrderCardLayout[standardPeople]"
                labelText="Standard people"
                className="spec-work-order-layout-field-standard-people"
              />
            </Columns>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
